if (!window.dataLayer || window.dataLayer === 'undefined') {
    window.dataLayer = [];
}

document.addEventListener('supiInitEnd', (e) => {
    const _supi = JSON.parse(Cookies.get('supi'));

    if (_supi) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'consent-status',
            allowedCookies: _supi.allowed,
        });
    }
});

document.addEventListener('bannerHide', (e) => {
    setTimeout(() => {
        const _supi = JSON.parse(Cookies.get('supi'));
        if (_supi) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'consent-status',
                allowedCookies: _supi.allowed,
            });
        }
    }, 500);
});

(function () {
    const d = document.querySelector('[data-dl-page-type]');

    if (d && d.dataset.dlPageType) {
        const e = { pageType: d.dataset.dlPageType };

        if (d.dataset.dlLocation) {
            e.location = d.dataset.dlLocation;
        }

        if (d.dataset.dlProduct) {
            e.product = d.dataset.dlProduct;
        }

        window.dataLayer.push(e);
    }
})();
